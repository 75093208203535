.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1 1000;

  &__piece {
    position: absolute;
    width: 10px;
    height: 30px;
    background: #ffd300;
    top: 0;
    opacity: 0;

    &:nth-child(1) {
      left: 7%;
      -webkit-transform: rotate(-40deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 182ms;
      -webkit-animation-duration: 1116ms;
    }

    &:nth-child(2) {
      left: 14%;
      -webkit-transform: rotate(4deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 161ms;
      -webkit-animation-duration: 1076ms;
    }

    &:nth-child(3) {
      left: 21%;
      -webkit-transform: rotate(-51deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 481ms;
      -webkit-animation-duration: 1103ms;
    }

    &:nth-child(4) {
      left: 28%;
      -webkit-transform: rotate(61deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 334ms;
      -webkit-animation-duration: 708ms;
    }

    &:nth-child(5) {
      left: 35%;
      -webkit-transform: rotate(-52deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 302ms;
      -webkit-animation-duration: 776ms;
    }

    &:nth-child(6) {
      left: 42%;
      -webkit-transform: rotate(38deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 180ms;
      -webkit-animation-duration: 1168ms;
    }

    &:nth-child(7) {
      left: 49%;
      -webkit-transform: rotate(11deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 395ms;
      -webkit-animation-duration: 1200ms;
    }

    &:nth-child(8) {
      left: 56%;
      -webkit-transform: rotate(49deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 14ms;
      -webkit-animation-duration: 887ms;
    }

    &:nth-child(9) {
      left: 63%;
      -webkit-transform: rotate(-72deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 149ms;
      -webkit-animation-duration: 805ms;
    }

    &:nth-child(10) {
      left: 70%;
      -webkit-transform: rotate(10deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 351ms;
      -webkit-animation-duration: 1059ms;
    }

    &:nth-child(11) {
      left: 77%;
      -webkit-transform: rotate(4deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 307ms;
      -webkit-animation-duration: 1132ms;
    }

    &:nth-child(12) {
      left: 84%;
      -webkit-transform: rotate(42deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 464ms;
      -webkit-animation-duration: 776ms;
    }

    &:nth-child(13) {
      left: 91%;
      -webkit-transform: rotate(-72deg);
      -webkit-animation: makeItRain 1000ms infinite ease-out;
      -webkit-animation-delay: 429ms;
      -webkit-animation-duration: 818ms;
    }

    &:nth-child(odd) {
      background: #7431e8;
    }

    &:nth-child(even) {
      z-index: 1;
    }

    &:nth-child(4n) {
      width: 5px;
      height: 12px;
      -webkit-animation-duration: 2000ms;
    }

    &:nth-child(3n) {
      width: 3px;
      height: 10px;
      -webkit-animation-duration: 2500ms;
      -webkit-animation-delay: 1000ms;
    }

    &:nth-child(4n-7) {
      background: red;
    }

  }
  @keyframes makeItRain {
    from {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    to {
      -webkit-transform: translateY(350px);
    }
  }
}

@import '../../styles/abstract/';

.card {
  height: 55vh;
  max-height: 60rem;
  min-height: 50rem;
  // width: 80vw;
  max-width: 37.7rem;
  overflow: auto;
  padding: 1.5rem;
  @include flex($gap: 2rem);
  color: $red-100;
  background-color: #242424;
  position: relative;
  border-radius: 0.35rem;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 0 0.75rem 0.45rem rgba(255, 255, 255, 0.4515);

  @include media-query($breakpoint-md) {
    overflow: hidden;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0.97) 0%,
          rgba(42, 42, 42, 0) 100%
        );
      }

      &:hover {
        cursor: pointer;

        // & .card__content {
        //   display: block;
        // }

        &::after {
          display: none;
        }
      }
  }

  &__header {
    @include flex($justify-content: space-between, $gap: 1.5rem);
    text-align: left;
    writing-mode: vertical-rl;
    rotate: 180deg;
    text-transform: uppercase;
    font-family: Drub;
    font-size: 1.8rem;
    @include media-query ($breakpoint-md) {
        font-size: 2.2rem;
    }
  }

  &__overlay {
    & .card__content {
      @include media-query($breakpoint-md) {
        display: block !important;
      }
    }
  }

  &__content {
    overflow: auto;
    font-family: Kanit;
    font-size: 1.6rem;
    
    @include media-query($breakpoint-md) {  
      display: none;
    }
  }

  &__description {text-align: left;
    &--list {
      color: $white-100;
      margin-top: 1.5rem;
    }
  }

  &__list {
    list-style: none;
  }
}

@import '../../styles/abstract/';

.contact-form {
  position: relative;
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  max-width: 85%;
  width: 45rem;
  color: $red-100;
  border: 5px solid $red-100;
  background-color: $white-100;
  font-size: 1.6rem;
  &::before {
    content: '';
    position: absolute;
    height: 105%;
    width: 105%;
    background-color: $white-100;
    top: -2%;
    left: 5%;
    z-index: -1;
  }
  &::after {
    content: '';
    position: absolute; 
    background-color: $red-100;
    height: 93%;
    width: 7%;
    top: 0;
    right: -7.5%;
  }
  & .content {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
    & .description {
      width: 80%;
      text-align: right;
    }
    & .button {
      color: inherit;
      border: none;
      border-bottom: 4px solid rgba(255, 0, 0, 0.383);
      outline: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }
  & form {
    & .input-group {
      padding: 0.5rem 2.5rem;
      display: flex;
      flex-direction: column;
      & input,
      & textarea {
        padding: 0.5rem;
        border: none;
        border-bottom: 1px solid $red-100;
        background: transparent;
      }
    }
    & button[type='submit'] {
      cursor: pointer;
      background-color: $red-100;
      text-transform: uppercase;
      border: none;
      outline: 0;
      color: $white-100;
      padding: 0.5rem 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      font-size: 1.5rem;
      margin-top: 3rem;
      & img {
        width: 1.85rem;
      }
    }
  }
}

@import './styles/index.scss';

* {
  margin: 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
  cursor: url(./assets/icons/mouse-red.svg), auto;
}

body #root {
  overflow: hidden;
}


.carousel-root {
  width: 100%;
}

.carousel-status {
  display: none;
}

.display-none {
  display: none !important;
}
@import '../../styles/abstract/';

.imagine {
  @include wrapper(true);
  @include flex(center, center, column, $gap: 2rem);
  // flex-wrap: wrap;
  // background-color: transparent;
  position: relative;

  @include media-query($breakpoint-sm) {
    justify-content: space-between;
    flex-direction: row;
  }

  &__content {
    padding: 4rem 2rem;
    &__heading {
      font-family: Drub;
      color: wheat;
      font-size: 4rem;
      color: $red-100;
      overflow: hidden;
      & span {
        --bg-size: 400%;
        background: linear-gradient(130deg, $red-100, $yellow-100, $red-100) 0 0 /
          var(--bg-size) 100%;
        color: transparent;
        background-clip: text;
        transform: translateY(5rem);
        display: inline-block;
        // animation: move-bg 12s linear infinite;

        &.fade-in {
          animation: move-bg 12s linear infinite, fadeIn 0.8s ease-in forwards,
            fadeOut 0.2s ease-in 1.6s forwards;
        }
      }
    }

    &__description {
      padding: 3rem 0;
      font-family: Roboto;
      font-size: 2rem;
      color: $white-100;
      max-width: 60rem;
    }
  }

  &__image {
    position: relative;
    height: 100%;
    & canvas {
      min-width: 90vw;
      @include media-query($breakpoint-sm) {
        min-width: 45rem;
      }
      aspect-ratio: 1/1;
    }

    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @keyframes fadeOut {
    to {
      transform: translateY(-5rem);
    }
  }

  @keyframes fadeIn {
    to {
      transform: translateY(0);
    }
  }

  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

@import '../../styles/abstract/';

.marketing {
  @include wrapper($include-padding: true);
  min-height: 100vh;
  overflow: hidden auto;

  &__container {
    @include flex(space-evenly, center, column, 2rem);
    position: relative;
    height: 100%;
  }

  &__header {
    font-family: Drub;
    font-size: 3.5rem;
    text-align: center;
    width: 100%;

    @include media-query($breakpoint-sm) {
      font-size: 5rem;
    }

    @include media-query($breakpoint-md) {
      width: 50vw;
      min-width: 74.1rem;
      font-size: 6.25rem;
    }

    text-transform: capitalize;
    --bg-size: 400%;
    background: linear-gradient(
        130deg,
        $red-100,
        $green-100,
        $blue-100,
        $red-100
      )
      0 0 / var(--bg-size) 100%;
    color: transparent;
    background-clip: text;
    animation: move-bg 12s linear infinite;
  }
  @keyframes fadeInHeader {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }

  &__sub-heading {
    color: $white-100;
    font-family: Roboto;
    font-size: 2rem;
    text-align: center;

    @include media-query($breakpoint-sm) {
      text-align: left;
    }
  }

  @keyframes fadeInSubHeading {
    from {
      transform: translateX(-10%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__card-list {
    @include flex(center, center, row, 10rem);
    width: 100%;
  }

  &__card {
    @include media-query($breakpoint-md) {
      transition: all 0.3s ease-in;

      // &--hover {
      //   &:hover {
      //     flex: 1;
      //   }
      // }
    }

    &--overlay {
      width: 100%;
      @include media-query($breakpoint-sm) {
        min-width: 45rem;
      }
      opacity: 0;
      animation: fadeInCard 0.5s ease-in forwards;
      &:hover {
        cursor: default;
      }
      &::after {
        display: none;
      }
    }

    @keyframes fadeInCard {
      to {
        opacity: 1;
      }
    }
  }

  & .carousel-root {
    width: 100%;
  }
}

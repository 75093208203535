@import '../../styles/abstract/variables';
@import "../../styles/abstract/colors";

.canvas {
    position: fixed;
    z-index: $z-index-canvas-background;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
@mixin flex(
  $justify-content: normal,
  $align-items: normal,
  $direction: row,
  $gap: 0
) {
  display: flex;

  @if $justify-content != normal {
    justify-content: $justify-content;
  }
  @if $align-items != normal {
    align-items: $align-items;
  }
  @if $direction != row {
    flex-direction: $direction;
  }
  @if $gap != 0 {
    gap: $gap;
  }
}

@mixin media-query($breakpoint) {
  @if $breakpoint == $breakpoint-sm {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if $breakpoint == $breakpoint-md {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if $breakpoint == $breakpoint-lg {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  } @else if $breakpoint == $breakpoint-xl {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  } @else {
    @error "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin wrapper($include-padding: false) {
  // background-color: $black-100;
  @if $include-padding == true {
    padding: 2rem;
    @include media-query($breakpoint-sm) {
      padding: 2rem 3rem;
    }
    @include media-query($breakpoint-md) {
      padding: 2rem 4.5rem;
    }
    @include media-query($breakpoint-lg) {
      padding: 2rem 7rem;
    }
  }
}

@import '../../styles/abstract/';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: $z-index-overlay;
    @include flex(center, center);
    background-color: rgba(0,0,0,0.5);

    &__container {
        padding: 2rem;
        max-width: 85vw;
        max-height: 85vh;
        overflow: auto;
    }
}
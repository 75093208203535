@import '../../styles/abstract/';

.whatWeDo {
  border-top: 0.6rem solid $white-100;
  padding: 5rem 0;
  height: auto;
  position: relative;

  &__header {
    @include flex(space-between, center, $gap: 0.5rem);
    margin: 5rem 0;
  }

  &__heading {
    font-family: Drub;
    font-size: 5.5rem;
    --bg-size: 100%;
    background: linear-gradient(130deg, $purple-100, $red-100) 0 0 /
      var(--bg-size) 100%;
    color: transparent;
    background-clip: text;
    display: inline-block;
  }

  &__speaker {
    &--right {
      transform: rotateY(180deg);
    }
  }

  &__content {
    @include flex(flex-start, center, column, 3rem);
    margin: 8rem 5rem 4rem;

    &--container {
      @include flex();
      width: 80%;

      &:nth-child(even) {
        justify-content: flex-end;
        & > div {
          transform: perspective(50rem) rotateY(-15deg);
          background: linear-gradient(
            to right,
            rgb(26, 26, 26),
            rgb(72, 72, 72)
          );
        }
      }

      &:nth-child(odd) {
        & > div {
          transform: perspective(50rem) rotateY(15deg);
          background: linear-gradient(
            to left,
            rgb(26, 26, 26),
            rgb(72, 72, 72)
          );
        }
      }
    }
  }

  &__card {
    border: 2px solid $red-100;
    width: 65rem;
    max-width: 80vh;
    aspect-ratio: 16/9;
    margin: 3rem;

    &__title {
      font-size: 4rem;
      text-transform: capitalize;
      font-family: 'Drub';
      text-align: center;
      padding: 2rem;
      color: red;
    }

    &__description {
      font-size: 3.5rem;
      color: whitesmoke;
      text-align: justify;
      padding: 1rem 3rem;
    }
  }
}

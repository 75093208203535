@import '../../styles/abstract/';

.container {
  position: relative;
  @include flex(center, center);
  @include wrapper(false);
  padding: 1.5rem;
  height: 100vh;
  overflow: hidden;
}

@keyframes gridMove {
  0% {
    background-position: 0%;
  }
  100% {
    background-position-y: 100%;
  }
}

.editor {
  // position: relative;
  height: 85vh;
  width: 100%;
  background-color: $white-80;
  @include flex($direction: column);

  min-height: 57rem;

  @include media-query($breakpoint-md) {
    height: 75vh;
  }

  &__header {
    border: 2px solid $black-100;
    padding: 1rem;
    @include flex(space-between, center, $gap: 1rem);
    & h3 {
      font-size: 1.5rem;
      text-transform: capitalize;
      font-family: Inter;
    }
  }

  &__content {
    flex: 1;
    // position: relative;
    border: 2px solid $black-100;
    padding: 1.25rem;
    background-size: 40px 40px;
    background-image: linear-gradient(to right, $grey-100 1px, transparent 1px),
      linear-gradient(to bottom, $grey-100 1px, transparent 1px);

    @include flex(normal, center);

    &__celebration {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: none;
      &--animate {
        display: block;
        z-index: 10;
        animation: celebrate 1.4s linear forwards;
      }
      @keyframes celebrate {
        to {
          display: none;
        }
      }
    }

    &__tooltip {
      position: fixed;
      z-index: 11;
      text-transform: capitalize;
      color: $white-100;
      background-color: $red-100;
      padding: 0.85rem;
      font-size: 1.5rem;
    }
  }

  &__action {
    flex: 1;
    @include flex(space-evenly, center, $gap: 2rem);
    flex-wrap: wrap;

    &__target {
      --left-value: 0px;
      position: relative;
      // left: 25%;
      background-color: $red-primary;
      min-width: 15rem;
      min-height: 15rem;
      border-radius: 100%;
      @include flex(center, center, column, 2rem);
      cursor: move;
      transition: all 0.5s ease-in;
      z-index: 10;

      &--glow {
        animation: glow 2s ease-in infinite;
      }

      &--animate {
        animation: targetMovement 1.5s ease-in;
      }

      &__logo {
        filter: invert(1);
      }

      @keyframes targetMovement {
        0% {
          left: 0;
        }
        50%{
          left: var(--left-value);
          opacity: 1;
        }
        80% {
          opacity: 0;
        }
        100% {
          scale: 0;
        }
      }

      @keyframes glow {
        0%,
        100% {
          box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.256);
        }
        50% {
          box-shadow: 0 0 5px 10px rgba(255, 0, 0, 0.256);
        }
      }
    }

    &__company {
      position: relative;
      // right: 25%;
      background-color: $white-70;
      width: 20rem;
      height: 20rem;
      border-radius: 100%;
      @include flex(center, center, column, 2rem);
      font-size: 2rem;
      text-transform: capitalize;
      overflow: hidden;
      transition: all 0.5s ease-in;
    }
  }

  &__bar {
    border-radius: 0.5rem;
    height: 100%;
    border: 2px solid $black-100;
    background: $white-100;

    &:nth-child(1) {
      & img {
        height: 100%;
        width: 100%;
      }
    }

    display: none;

    @include media-query($breakpoint-md) {
      display: inherit;
    }
  }
}

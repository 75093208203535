@import '../../styles/abstract/';

.hero {
  @include wrapper($include-padding: false);
  @include flex($direction: column);
  height: 100vh;
  font-family: Drub;

  & * {
    position: relative;
  }

  &__content {
    font-size: 2rem;
    color: $white-100;
    opacity: 0;
    animation: fadeInContent 0.3s ease-in 1.8s forwards;
  }
  &__sub-content {
    font-size: 2.2rem;
    color: $white-100;
    opacity: 0;
    animation: fadeInContent 0.3s ease-in 1.8s forwards; 
  }

  @keyframes fadeInContent {
    to {
      opacity: 1;
    }
  }

  &__canvas-container{
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(243, 0, 0, 0.89);
  }

  &__container {
    flex: 1;
    @include flex(center, center, column, 4rem);
  }

  &__name {
    position: relative;
    color: $white-100;
    font-size: 10vw;
    -webkit-text-stroke: 0.3vw $red-100;
    letter-spacing: -1vw;
    text-shadow: 0 1rem 10px rgba(0, 0, 0, 0.2);

    @include media-query($breakpoint-md) {
      text-shadow: 0 1.6rem 10px rgba(0, 0, 0, 0.2);
    }

    &__letter {
      display: inline-block;
      opacity: 0;

      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          @if $i ==6 {
            animation: fadeInLetter 0.3s ease-in (($i - 1) * 0.3s) forwards,
              rotateLetter 0.3s ease-in (($i - 1) * 0.3s) forwards;
          }

          @else {
            animation: fadeInLetter 0.3s ease-in (($i - 1) * 0.3s) forwards;
          }
        }
      }

      &::before {
        content: attr(data);
        position: absolute;
        right: -1vw;
        color: $red-100;
        -webkit-text-stroke: 0.4vw $white-100;
        z-index: -1;
      }
    }
  }

  &__button {
    cursor: pointer;
    text-transform: uppercase;
    background-color: $white-100;
    outline: 0;
    border: 3px solid $red-100;
    color: $red-100;

    font-size: 1.2rem;
    padding: 0.5rem;
    letter-spacing: 1px;

    &::before {
      content: '';
      position: absolute;
      height: calc(100% + 1rem);
      width: calc(100% + 9%);
      top: -0.5rem;
      left: 0.8rem;
      background-color: $white-100;
      z-index: -1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0.1%;
      left: 100%;
      height: 98%;
      width: 14%;
      background-color: $red-100;
    }

    @include media-query($breakpoint-sm) {
      font-size: 1.75vw;
      padding: 1rem;
      letter-spacing: 0;

      &::after {
        width: 12%;
      }
    }

    @include media-query($breakpoint-xl) {
      border: 0.185vw solid $red-100;

      &::before {
        width: calc(100% + 1.3vw);
        height: calc(100% + 0.85vw);
        top: -15%
      }
    }
  }

  @keyframes rotateLetter {
    from {
      scale: 2;
      rotate: -360deg;
    }

    to {
      scale: 1;
      rotate: 0;
    }
  }

  @keyframes fadeInLetter {
    from {
      transform: translateX(-5rem) scale(1.5);
    }

    to {
      opacity: 1;
      transform: translateX(0) scale(1);
    }
  }

  &__footer {
    overflow: hidden;
    padding: 2rem 0;
    color: white;

    &__marquee {
      font-size: 3.2vw;
      white-space: nowrap;
      animation: textMovement 6s linear infinite;

      &:before {
        content: attr(data);
      }

      &:after {
        content: attr(data);
        position: absolute;
        left: 100%;
        top: 0;
        padding: inherit;
      }
    }

    @keyframes textMovement {
      to {
        transform: translateX(-100%);
      }
    }
  }
}
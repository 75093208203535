@import '../../styles/abstract/';

.navbar {
  position: fixed;
  z-index: $z-index-navbar;
  top: 0;
  left: 0;
  width: 100%;
  @include flex($direction: column);
  padding: 1.5rem 2rem;
  @include media-query($breakpoint-sm) {
    padding: 2rem 3rem;
  }
  @include media-query($breakpoint-md) {
    padding: 3rem 4.5rem;
  }
  @include media-query($breakpoint-lg) {
    padding: 5rem 7rem;
  }

  &__menu {

    $blur-value: 8px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    &__option {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur($blur-value);
      @include flex(center, center, column, 8rem);
    }
  }

  @keyframes fadeInBackground {
    to {
      height: 100%;
      width: 100%;
    }
  }

  &__content {
    @include flex(space-between, center, $gap: 2rem);
    position: relative;
    z-index: $z-index-navbar + 1;
  }

  &__logo {
    width: 10%;
    min-width: 7.5rem;
    // max-width: 11rem;

    &:hover {
      fill: $red-100;
      stroke: $white-100;
    }

    & path:nth-child(1) {
      opacity: 0;
      transform: translateX(-50%);
      animation: fadeIn 0.3s ease-in forwards;
    }

    & path:nth-child(2) {
      opacity: 0;
      transform: translateX(-50%);
      animation: fadeIn 0.3s ease-in 0.3s forwards;
    }

    & rect {
      opacity: 0;
      transform: translateX(-50%);
      animation: fadeIn 0.3s ease-in 0.6s forwards;
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &__option-container {
    @include flex(flex-end, center, row, 1rem);
    flex: 1;
  }

  &__option {
    cursor: pointer;
    opacity: 0;
    transform: translateX(100%);
    width: 7.5%;
    min-width: 2rem;
    max-width: 4.8rem;

    animation: fadeIn 0.5s ease-in 0.9s forwards;

    &:hover {
      & rect {
        fill: $red-100;
        stroke: $white-100;
      }
      & path {
        stroke: $white-100 !important;
      }
    }

    &--audio {
      scale: 0.3;
    }

    & path {
      stroke-dasharray: 100;
      stroke: $red-100;
      animation: strokeFadeIn 6s ease-in infinite forwards,
        strokeColor 0.2s ease-in 1.9s forwards;
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @keyframes strokeFadeIn {
      0% {
        stroke-dashoffset: 0;
      }
      25% {
        stroke-dashoffset: 100;
      }
      50% {
        stroke-dashoffset: 200;
      }
      75% {
        stroke-dashoffset: 100;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
    @keyframes strokeColor {
      to {
        stroke: $black-100;
      }
    }
  }
}

.banner {
  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    @include flex(center, center);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
  }
  position: relative;
  width: 60%;
  background-color: $black-100;
  z-index: $z-index-overlay;
  padding: 5rem;
  color: $white-100;
  &__heading {
    font-family: Drub;
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }
  &__content {
    font-family: Roboto;
    font-size: 1.8rem;
    margin-top: 2rem;
  }
  &__actions {
    @include flex(flex-end, center, row, 2rem);
    flex-wrap: wrap;
    margin: 3rem 0 0;
  }
  &__btn {
    cursor: pointer;
    flex: 1;
    max-width: 20rem;
    padding: 1.5rem 2rem;
    font-family: Roboto;
    font-size: 1.8rem;
    outline: 0;
    border: none;
    transition: all 0.3s ease-in;

    &:hover {
      background-color: $red-primary;
      color: $white-100;
    }

    &--primary {
      background-color: #e64848;
      color: $white-100;
    }
  }
}

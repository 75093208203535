$red-100: #ff0000;
$green-100: #008000;
$yellow-100: #ffff00;
$blue-100: #0000ff;
$white-100: #ffffff;
$white-90: #f5f5f5;
$white-80: #ebebeb;
$white-70: #DAD9D9;
$black-100: #000000;
$grey-100: #808080;
$purple-100: #800080;

$red-primary: #e50000;
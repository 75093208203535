@import '../../styles/abstract/';

.video {
  @include wrapper($include-padding: false);
  @include flex(center, center);
  height: 100vh;
  border-top: 0.6rem solid $white-100;

  &__video-container {
    width: 90vw;
    max-width: 200rem;
    @include media-query($breakpoint-md) {
      width: 75vw;
    }

    aspect-ratio: 16/9;

    &--fadeIn {
      animation: fadeInVideo 1s ease-in forwards;
    }

    &--fadeOut {
      animation: fadeOutVideo 1s ease-in forwards;
    }

    & span {
      opacity: 0;
      transition: 0.3s all ease;
      color: $white-100;
      position: absolute;
      left: 50%;
      bottom: 0;
      font-size: 1rem;
      font-family: Inter;
      transform: translate(-50%);
      
      @include media-query($breakpoint-md) {
        bottom: 2%;
        font-size: 3rem;
      }
    }

    &:hover span {
      opacity: 1;
    }
  }

  @keyframes fadeInVideo {
    from {
      opacity: 0;
      transform: translate(100%, 100%);
    }
    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes fadeOutVideo {
    from {
      opacity: 1;
      transform: translate(0);
    }
    to {
      transform: translate(-100%);
      opacity: 0;
    }
  }

  &__video-player {
    cursor: pointer;
    height: 100%;
    width: 100%;

    &__mask {
      -webkit-mask-image: url(../../assets/mask/video-mask.svg);
      -webkit-mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-size: cover;

      mask-image: url(../../assets/mask/video-mask.svg);
      mask-position: center center;
      mask-repeat: no-repeat;
      mask-size: cover;
    }
  }
}

@import '../../styles/abstract/';

.call-form {
    @include wrapper(true);
    @include flex(center, center, row, 3rem);
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    height: 100vh;
    overflow-y: auto;
    // background-color: transparent;

    &__logo-container {
        display: none;
        position: relative;
        @include media-query($breakpoint-md) {
            @include flex(center, center);
        }
        flex: 1;
        max-width: 100rem;
        height: 100%;
        max-height: 35vh;
        min-width: 30rem;
        & canvas {   
            width: 100%;
            height: 100%;
        }

        & img {
            position: absolute;
            width: 90%;
            max-width: 30rem;
            @include media-query ($breakpoint-sm) {
                width: 100%;
            }
        }
    }

    &__form-container {
        flex: 1;
        min-width: 30rem;
    }
}
@font-face {
    font-family: Drub;
    src: url(../../fonts/druk-wide-bold.woff) format('woff');
}

@font-face {
    font-family: Kanit;
    src: url(../../fonts/Kanit-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Kanit;
    src: url(../../fonts/Kanit-Bold.woff) format('woff');
    font-weight: 600;
    font-style: bold;
}

@font-face {
    font-family: Roboto;
    src: url(../../fonts/Roboto-Regular.woff) format('woff');
}

@font-face {
    font-family: Inter;
    src: url(../../fonts/Inter-Regular.woff) format('woff');
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url(../../fonts/IBMPlexMono-Regular.woff) format('woff');
}
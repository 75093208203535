@import '../../styles/abstract/';

.monitor-section {
  @include wrapper(false);
  min-height: 100vh;
  overflow: visible;
  position: relative;

  @include flex(center, center);

  &__strip {
    position: absolute;
    height: 250vw;
    padding: 1rem;
    overflow: hidden;
    &--left {
      background-color: $red-primary;
      transform: rotate3d(-1, 1, 1, 105deg) rotateZ(75deg) rotateY(-142deg) rotateX(-16deg);
      color: $white-100;
      & p {
        rotate: 180deg;
      }
    }
    &--right {
      background-color: $white-100;
      transform: rotate3d(-1, 1, 1, 100deg) rotateZ(68deg) rotateY(-537deg) rotateX(190deg);
      color: $red-100;
    }

    &__marquee {
      font-size: 3.2vw;
      white-space: nowrap;
      animation: textMovement 16s linear infinite;
      writing-mode: vertical-lr;
      height: 100%;
      font-family: Inter;
      letter-spacing: 0.25rem;

      &:before {
        content: attr(data);
      }

      &:after {
        content: attr(data);
        position: absolute;
        left: 0;
        top: 100%;
        padding: inherit;
      }
    }

    @keyframes textMovement {
      to {
        transform: translateY(-100%);
      }
    }
  }

  &__monitor {
    position: relative;
    width: auto;
    max-width: 50%;

    &--image {
      width: 100%;
    }
    &--screen {
      position: absolute;
      top: 3%;
      left: 2.7%;
      height: 63.3%;
      width: 94.75%;
      overflow: hidden;
    }
  }
}

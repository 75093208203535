@import '../../styles/abstract/';

.contact {
  position: relative;
  height: 100vh;
  overflow: hidden;

  &__container {
    height: 100%;
    @include flex(flex-end, center, column);
    padding: 2rem;

    @include media-query($breakpoint-sm) {
      padding: 3rem;
    }
  }

  &__loud-text {
    @include flex(center, center);
    font-family: Drub;
    font-size: 2.85rem;
    padding: 1rem;
    @include media-query($breakpoint-sm) {
      font-size: 4rem;
      padding: 1.5rem;
    }
    @include media-query($breakpoint-md) {
      font-size: 5rem;
      padding: 2rem;
    }
    @include media-query($breakpoint-lg) {
      font-size: 6rem;
      padding: 2.5rem;
    }
    color: transparent;
    // flex: 1;
    text-transform: uppercase;
    color: black;
    -webkit-text-stroke: 1px white;
    text-shadow: 0 0 5px white;
    letter-spacing: 10px;
    opacity: 0;
    transform: translateY(20%);
    transition: 0.3s all;
    @for $i from 1 through 5 {
        &:nth-child(#{$i}){
            animation: fadeIn 0.3s ease-in 1.5s - ($i - 1) * 0.3s forwards, redTextGlow 1.6s ease-in infinite;
        }
    }

    &--vanish {
      animation: vanish 0.3s ease-in forwards !important;
    }
  }

  @keyframes vanish {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    to {
        color: $red-100;
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes redTextGlow {
    0%, 100% {
        text-shadow: 0 0 0 $white-100;
    }
    50% {
        text-shadow: 0 0 2rem $white-100;
    }
  }

  &__canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__call-btn {
    padding: 1rem 2rem;
    font-family: Drub;
    font-size: 1.6rem;
    letter-spacing: 0.25rem;
    text-transform: capitalize;
    color: $red-100;
    background-color: $white-100;
    border: 1px solid $red-100;
    cursor: pointer;
    position: relative;
    z-index: 1;
    &--glow {
      animation: glow 1s ease-in infinite;
    }

    @keyframes glow {
      0%,
      100% {
        box-shadow: 0 0 0 0 $red-100;
      }
      50% {
        box-shadow: 0 0 1rem 1rem $red-100;
      }
    }
  }
}

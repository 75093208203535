@import '../../styles/abstract/';

.frost {
  border-top: 6px solid $white-100;
  position: relative;
}

.footer {
  background: linear-gradient(to bottom, rgba(255,255,255,0.2), rgba(255,255,255,0.1),rgba(255,255,255,0.2));
  @include flex($align-items: center, $direction: column);
  @include wrapper($include-padding: true);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-family: Drub;
  font-size: 1.6rem;
  color: $red-100;
  text-align: center;
  position: relative;
  z-index: 1;

  @include media-query($breakpoint-md) {
    flex-direction: row;
    font-size: 2.5rem;
  }

  &__heading {
    @include flex($direction: column);
  }

  &__list {
    margin-top: 2.5rem;
    color: $white-100;
    list-style: none;
    padding: 0;
    font-size: 1.3rem;
    & li {
      cursor: pointer;
      text-transform: capitalize;
      margin-top: 1rem;
      @include media-query($breakpoint-md) {
        margin-top: 2rem;
      }
    }

    @include media-query($breakpoint-md) {
      margin-top: 4.5rem;
    }
  }

  &__para {
    font-family: Inter;
    font-size: 1.33rem;
    margin-top: 2.5rem;
    @include media-query($breakpoint-md) {
      margin-top: 4.5rem;
    }
  }

  &__about {
    position: relative;
    padding: 1.5rem 0;
    flex: 1;
    @include media-query($breakpoint-md) {
      text-align: left;
      padding: 5.5rem 0;
    }
  }

  &__logo {
    position: relative;
    z-index: 1;
    order: -1;
    @include flex(center, center);
    padding: 1.5rem 0;
    flex: 1;
    & img {
      width: 85%;
      max-width: 31.7rem;
      position: relative;
      z-index: 1;
      transition: all 0.3s linear;
      animation: jiggleEffect 20s ease-in-out infinite;
    }
    & canvas {
      position: absolute;
      max-height: 100%;
    }

    @include media-query($breakpoint-md) {
      order: initial;
      padding: 5.5rem 0;
    }
  }

  @keyframes jiggleEffect {
    0%,
    100% {
      rotate: 0;
      transform: translate(0, 0);
    }
    33% {
      rotate: -5deg;
      transform: translate(-30%, 10%);
    }
    66% {
      rotate: 5deg;
      transform: translate(30%, -10%);
    }
  }

  &__navigation {
    position: relative;
    padding: 1.5rem 0;
    flex: 1;
    @include media-query($breakpoint-md) {
      text-align: right;
      padding: 5.5rem 0;
    }
  }
}

@import '../../styles/abstract/';

.wrapper {
  position: relative;
  border-top: 0.6rem solid $white-100;
}

.about {
  @include wrapper(true);
  position: relative;
  max-width: $breakpoint-xl;
  margin: 0 auto;
  
  &__header {
    font-family: Drub;
    color: $white-100;
    &__sub-heading {
      text-align: center;
      font-size: 1.5rem;
      margin: 2rem auto 0;
      width: 100%;
      @include media-query($breakpoint-sm) {
        margin: 4rem auto 0;
        font-size: 2rem;
      }
      @include media-query($breakpoint-md) {
        font-size: 3rem;
        max-width: 80%;
        margin: 6rem auto 0;
      }
    }

    &__heading {
      text-transform: uppercase;
      text-align: center;
      font-size: 2rem;
      margin: 2rem auto 0;
      @include media-query($breakpoint-sm) {
        font-size: 3.5rem;
        margin: 4rem auto 0;
      }
      @include media-query($breakpoint-md) {
        font-size: 4.5rem;
        margin: 6rem auto 0;
      }
    }
  }

  &__card {
    max-width: 60rem;
    position: relative;
    // background-color: $red-100;
    font-size: 2rem;
    font-family: Roboto;
    border: 3px solid;
    overflow: hidden;
    border-image: linear-gradient(to right, transparent, red, transparent) 1;
    border-right: none;

    @include media-query($breakpoint-md) {
      font-size: 2.8rem;
      transform-origin: 0 50%;
      transform: perspective(10rem) rotateY(3deg);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      filter: blur(3rem);
      background: $red-primary;
      @include media-query($breakpoint-md) {
        background: linear-gradient(
          to right,
          $red-100 50%,
          rgba(0, 0, 0, 0.5) 95%
        );
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -2.8rem;
      height: 100%;
      width: 2.8rem;
      background: linear-gradient(
        to right,
        $red-100 50%,
        rgba(0, 0, 0, 0.5) 95%
      );
      filter: blur(3rem);
      z-index: 1;
    }

    & p {
      padding: 2rem;
      position: relative;
      z-index: 2;
      overflow: auto;
    }
  }

  &__co-founder {
    @include flex(space-between, $direction: column, $gap: 2rem);
    color: $white-100;
    @include media-query($breakpoint-md) {
      flex-direction: row;
    }
    &__content {
      position: relative;
      & img {
        width: 100%;
        max-width: 74.1rem;
      }
      &__sub-title {
        position: absolute;
        top: 33vw;
        font-size: 1.5rem;
        font-family: Drub;
        text-transform: uppercase;

        @include media-query($breakpoint-md) {
          top: 20vw;
          font-size: 3rem;
          letter-spacing: 0.2rem;
        }
        @include media-query($breakpoint-lg) {
          top: 45%;
        }
      }
    }

    &__img {
      @include flex(center, flex-end);
      & img {
        width: 100%;
        max-width: 44.9rem;
      }
    }
  }

  &__hey-founder {
    @include flex(space-between, center, row, 2rem);
  }
}

@import '../../styles/abstract/';

.ai-tool {
  @include wrapper(true);
  position: relative;
  // z-index: 100;

  &__header {
    font-size: 1.4rem;
    color: $white-100;
    @include flex(space-around, center, column, 1.5rem);
    text-align: center;
    text-transform: uppercase;
    font-family: 'IBM Plex Mono';
    background-color: $black-100;
    padding: 1rem;
    @include media-query($breakpoint-sm) {
      font-size: 2.4rem;
      padding: 2rem 5.5rem;
    }

    @include media-query($breakpoint-md) {
      flex-direction: row;
    }
  }
  &__container {
    background-color: $black-100;
    border: 1px solid whitesmoke;
    &__header {
      @include flex(space-between, center, $gap: 1.75rem);
      padding: 1rem;
      color: $white-100;
      font-family: 'IBM Plex Mono';

      & button {
        text-transform: uppercase;
      }
    }

    &__head {
      background-color: $white-100;
      padding: 2.25rem 1rem;
      & span {
        font-family: Roboto;
        font-size: 1.5rem;

        @include media-query($breakpoint-sm) {
          font-size: 1.8rem;
        }
      }
      & button {
        margin-left: 1rem;
        border-radius: 0.5rem;
        font-family: Inter;
      }
    }

    &__main {
      background-color: $white-100;
      border-top: 1px solid $black-100;
      border-bottom: 1px solid $black-100;
      padding: 1.5rem;
      @include flex($align-items: center, $gap: 1.5rem);
      font-size: 1.4rem;
      & textarea {
        flex: 1;
        color: #c0c0c0;
        font-family: Inter;
        padding: 1rem;
        min-height: 10rem;
      }

      & button {
        font-family: Roboto;
        text-transform: capitalize;
        padding: 0.75rem 2rem;
      }
    }

    &__footer {
      padding: 1rem;
      background-color: $white-100;
    }

    &__heading {
      font-size: 1.2rem;
      text-transform: uppercase;

      @include media-query($breakpoint-sm) {
        font-size: 1.6rem;
      }
    }
  }

  &__option {
    @include flex($gap: 0.25rem);
  }

  &__dot {
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 100%;
    border: 1px solid whitesmoke;
  }

  &__btn {
    padding: 0.25rem 0.5rem;
    outline: 0;
    border: none;
    font-size: 1.2rem;

    @include media-query($breakpoint-sm) {
      font-size: 1.5rem;
    }

    &--light {
      background-color: #ebe7e7;
      color: $black-100;
    }

    &--dark {
      background-color: $black-100;
      color: $white-100;
    }
  }
}

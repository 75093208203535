@import '../../styles/abstract/';

.text-animate {
  @include flex(center);
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  font-size: 3rem;
  @include media-query($breakpoint-lg) {
    font-size: 5rem;
    width: 42vw;
    &:nth-child(odd) {
      justify-content: flex-start;
    }

    &:nth-child(even) {
      justify-content: flex-end;
    }
  }
  text-transform: uppercase;
  font-family: Drub;

  opacity: 0;
  scale: 0;

  &:hover span {
    color: $red-100 !important;
  }

  & span {
    color: $white-100;
    transition: color 0.2s ease-in;
    -webkit-text-stroke: 2px $white-100;
    // opacity: 0;
  }

  @for $i from 1 through 4 {
    &:nth-child(#{$i}) {
      animation: fadeIn 0.3s ease-in ($i - 1) * 0.3s forwards;
    }
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
      scale: 1;
    }
  }

}
